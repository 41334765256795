import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo.png';

const Header = ({ siteTitle }) => (
  <div>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1rem 1.0875rem',
      }}
    >
      <img src={logo} alt="Once y Once" height="70" style={{ margin: 0 }} />
    </div>
  </div>
)

export default Header
