import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div>
      <p>
        Entendemos que las oportunidades son únicos e irrepetibles, por lo que pensamos en todos los detalles. Buscamos aprovechar esa oportunidad única para generar una experiencia memorable y que como resultado se logre un vínculo mas fuerte entre la marca y los clientes.
      </p>
      <p>
        Entendemos que las oportunidades son únicos e irrepetibles, por lo que pensamos en todos los detalles. Buscamos aprovechar esa oportunidad única para generar una experiencia memorable y que como resultado se logre un vínculo mas fuerte entre la marca y los clientes.
      </p>
      <p>
        Partimos de la premisa de entender qué se necesita, para qué se necesita y quién la necesita y de esta manera crear experiencias que nos permitan proyectar un mensaje claro.
      </p>
      <p>
        Nuestra experiencia abarca todo lo relacionado con la concepción, el diseño y montaje de las más variadas puestas en escena de espectáculos, lanzamientos, ruedas de prensa, relaciones públicas y redes sociales.
      </p>
    </div>
  </Layout>
)

export default IndexPage
