import React from 'react'

const Jumbotron = () => (
  <div className="jumbotron">
    <div className="container">
      <h1>En Once y Once somos estrategas en comunicación.</h1>
      <div style={{ fontSize: '25px', lineHeight: 1.5, marginBottom: '2rem' }}>
        Somos un equipo conformado por <span className="highlight">publicistas, cineastas, diseñadores gráficos y gestores culturales</span> pensando cada proyecto.
      </div>
      <a href="mailto:nono@sonlasonceyonce.com" target="_blank" rel="noopener" className="btn">
        Contáctanos
      </a>
      </div>
  </div>
)

export default Jumbotron
